<template>
    <div class="container">
        <HeaderMain/>
        <div class="route">
            <router-view :key="$route.fullPath"></router-view>
            <FooterMain/>
        </div>
    </div>
</template>
<script>
import HeaderMain from '@/views/HeaderMain'
import FooterMain from '@/views/FooterMain'
export default {
    data() {
        return {
            
        } 
    },
    components:{
        HeaderMain,
        FooterMain
    }
}
</script>
<style lang="scss" scoped>
.container{
    width: 100%;
    overflow: hidden;
}
    .route{
        position: relative;
        top: 80px;
        width: 100%;
    }
</style>