<template>
  <div id="app">
    <ProductMain/>
  </div>
</template>
<script>
import ProductMain from '@/views/ProductMain'
export default {
    components:{
      ProductMain
    }
}
</script>
<style>
body{
  background: #F7F8FA !important;
}
   ::-webkit-scrollbar {display:none}

</style>
