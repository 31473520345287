<template>
  <div class="header">
    <div class="nav">
      <a-row class="header-nav">
        <a-col span="4" class="header-logo">
          <img :src="require('@assets/logo.png')" width="60" height="80%" />
        </a-col>
        <a-col span="14" class="nav-titles">
          <a-col
            span="4"
            class="nav-title"
            v-for="(item, i) in menuList"
            :key="i"
          >
            <div
              class="nav-title-font"
              @click="PushRoute(item)"
            >
              {{ item.title }}
            </div>
            <div class="nav-title-solid"></div>
          </a-col>
        </a-col>
        <a-col :sm="10" :md="6" :xl="6" class="header-tel">
          <a-col>
            <img :src="require('@assets/Tel.jpg')" alt="" srcset="" />
            0755-83359688
          </a-col>
          <a-col span="4">
            <img :src="require('@assets/weixin.png')" alt="" srcset="" />
          </a-col>
        </a-col>
        <a-col class="menu" @click="click_meun">
          <svg
            t="1650609455886"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2155"
            width="35"
            height="35"
          >
            <path
              d="M0 227.555556V0h1024v227.555556zM0 625.777778V398.222222h1024v227.555556zM0 1024v-227.555556h1024v227.555556z"
              p-id="2156"
              fill="#bfbfbf"
            ></path>
          </svg>
        </a-col>
      </a-row>
    </div>
    <div class="menus" v-if="vilible">
      <div v-for="(item, i) in menuList" @click="PushRoute(item)" :key="i">
        {{ item.title }}
      </div>
    </div>
    <div class="bgs" v-if="vilible" @click="vilible = false"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      vilible: false,
      menuList: [
        {
          title: "首页",
          url: "/",
          num:1,
        },
        {
          title: "走进明香",
          url: "/Recruitment",
          num:2
        },
        {
          title: "新闻动态",
          url: "/news",
          num:2
        },
        {
          title: "招商合作",
          url: "/Recruitment",
          num:2
        },
        {
          title: "招贤纳士",
          url: "/Recruitment",
          num:2
        },
        {
          title: "联系我们",
          url: "/connect",
          num:2
        },
      ],
    };
  },
  
  methods: {
    PushRoute(e) {
      this.vilible = false;
      if (e.num===2) {
        this.$router.push({
          path: `${e.url}/${e.title}`,
        });
      } else {
        this.$router.push({
          path: `${e.url}`,
        });
      }
    },
    click_meun() {
      this.vilible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.bgs {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  // display: none;
  background: rgba(0, 0, 0, 0.3);
}
.header {
  height: 80px;
  position: fixed;
  z-index: 999;
  width: 100%;
  padding: 0 15px;
  background: rgba(255, 255, 255, 1);
  .nav,
  .header-nav {
    height: 100%;
    position: relative;
  }
  img {
    position: relative;
    top: -2px;
  }
  .header-logo,
  .header-tel,
  .nav-titles {
    display: flex;
    height: 100%;
    font-size: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .header-tel {
    color: #c50018;
  }
  .nav-titles {
    justify-content: space-evenly;
  }
  .nav-title:hover {
    .nav-title-font {
      color: #da251d;
    }
    .nav-title-solid {
      width: 80%;
    }
  }
  .nav-title-solid {
    width: 0px;
    height: 3px;
    position: relative;
    top: 8px;
    margin: 0 auto;
    transition: all 0.3s;
    background: #da251d;
  }
}
.menu {
  width: 35px;
  height: 80px;
  position: absolute !important ;
  right: 5px;
  display: none;
  align-items: center;
  cursor: pointer;
  top: 0px;
}
.menus {
  width: 100%;
  background: white;
  text-align: center;
  padding: 15px;
  z-index: 999;
  position: relative;
  border-radius: 12px;
  div {
    padding: 8px;
    font-size: 18px;
    cursor: pointer;
  }
  div:hover {
    color: #ffc107;
  }
}

@media all and (max-width: 800px) {
  .header {
    .nav-titles {
      display: none !important;
    }
  }
  .menu {
    display: flex;
  }
}
</style>
