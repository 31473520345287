let baseUrl; //请求地址
if (process.env.NODE_ENV === 'development') {
	baseUrl = 'https://www.mtzh.top/api/all'
} else {
	baseUrl = 'https://www.mtzh.top/api/all'
}

import axios from "axios";
 function getAction(opts_url,data) {
	opts_url=baseUrl+opts_url
	if(data){
		opts_url+="?"
		for(let i in data){
			opts_url+=`${i}=${data[i]}&`
		}
	}
	let promise = new Promise(function(resolve, reject) {
		axios.get(opts_url).then(res=>{
			resolve(res)
		}).catch(res=>{
			reject(res)
		})
	})
	return promise
};

function postAciton (opts_url,data){
	opts_url=baseUrl+opts_url
	let headers={
		'X-Requested-With': 'XMLHttpRequest',
		"Accept": "application/json",
		"Content-Type": "application/json; charset=UTF-8",
	}
	let promise = new Promise(function(resolve, reject) {
		axios.post(opts_url,data,headers).then(res=>{
			resolve(res)
		}).catch(res=>{
			reject(res)
		})
	})
	return	promise
}

export {getAction ,postAciton}