import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import( '../views/FrontPage.vue')
  },
  {
    path: '/news/:title',
    name: 'news',
    component: () => import( '../views/DynamicNews.vue')
  },
  {
    path: '/news_t',
    name: 'news_t',
    component: () => import( '../views/DynamicNews_t.vue')
  },
  {
    path: '/Recruitment/:title',
    name: 'Recruitment',
    component: () => import( '../views/RecruitmentMain.vue')
  },
  {
    path: '/connect/:title',
    name: 'connect',
    component: () => import( '../views/connectMain.vue')
  },
  
]

const router = new VueRouter({
  routes
})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
