<template>
  <div class="footer">
    <a-row class="footer_row">
      <a-col
        :sm=24
        :md=10
        :xl=10
        class="left"
      >
        <div class="footer_left">
          <div v-html="msg[0]"></div>
          <p>
            <span
              :href="item.url"
              v-for="(item,i) in menuList"
              :key="i"
              @click="PushRoute(item)" 
              style="margin-right:8px;cursor:pointer"
            >{{item.title}}</span>
          </p>

          <p><img
              :src="require('@assets/dizhi.png')"
              alt=""
              srcset=""
            > <span v-html="msg[1]"></span></p>
          <a-col>
            <img
              :src="require('@assets/footer_tel.png')"
              alt=""
            >
            <span class="tel" v-html="msg[2]"></span>
          </a-col>
        </div>
      </a-col>
      <a-col
        :sm=24
        :md=13
        :xl=13
        class="right"
      >
        <div class="code_container">
          <div
            class="code_img"
            v-for="(item,i) in msgCode"
            :key="i"
          >
            <img
              :src="item.value"
              alt=""
            >
            <span>{{item.title}}</span>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  data() {
 return{
       menuList: [
        {
          title: "走进明香",
          url: "/Recruitment",
        },
        {
          title: "新闻动态",
          url: "/news",
        },
        {
          title: "招商合作",
          url: "/Recruitment",
        },
        {
          title: "招贤纳士",
          url: "/Recruitment",
        },
        {
          title: "联系我们",
          url: "/connect",
        },
      ],
      msg:[],
      msgCode:[],
    };
  },
  created(){
    this.loadData()
  },
  methods:{
     async loadData(){
          const {data:{Data:resultMsg}} =  await this.get(`/AllMXJTHtml/GetContneList`,{
                "args[pageindex]": 1,
                "args[pagesize]": 999,
                type:'底部',
                subtype: '信息',
          });
          this.msg=resultMsg.map((item)=>{
            return item.__HtmlContent
          })
          const {data:{Data:resultCode}}  =  await this.get(`/AllMXJTHtml/GetContneList`,{
                "args[pageindex]": 1,
                "args[pagesize]": 999,
                type:'底部',
                subtype: '二维码',
          });
          this.msgCode=resultCode.map((item)=>{
            return {
              title:item.Title,
              value:item.ListPicPath
            }
          })
          console.log(this.msgCode);
      },
      PushRoute(e) {
        window.scrollTo(0,0);
          this.$router.push({
            path: `${e.url}/${e.title}`,
      });

    },
  }
};
</script>
<style lang="scss" scoped>
a {
  color: #808080;
}
.footer {
  padding-bottom: 50px;
  font-size: 16px;
  margin-bottom: 100px;
  .footer_row {
    display: flex;
    flex-wrap: wrap;
  }
  .left {
    overflow: hidden;
  }
  .footer_left {
    margin: 0 auto;
    position: relative;
    left: 20%;
    text-align: left;
    .tel {
      font-size: 25px;
      display: inline-block;
      font-weight: bolder;
      color: #da251d;
      position: relative;
      top: 5px;
      margin-left: 18px;
    }
  }
  .code_container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .code_img {
      img {
        width: 120px;
        height: 120px;
      }
      span {
        text-align: center;
        display: block;
      }
    }
  }
}
@media all and (max-width: 800px) {
  .left {
    text-align: center !important;
    margin-top: 50px;
    width: 100%;
    order: 10;
    .footer_left {
      left: 0;
      text-align: center !important;
    }
  }
  .footer .footer_row{
    justify-content: center;
  }
  .footer .code_container {
    margin: 0 auto;
    justify-content: center;
    flex-wrap: nowrap;
    .code_img {
        width: 75px;
        height: 75px;
        text-align: center;
      img {
        width: 60px;
        height: 60px;
      }
      span {
        font-size: 12px;
      }
    }
  }
}
@media all and (max-width:576px) {
}
</style>
